const getDefaultState = () => {
    return {
        layThongTinXepHangTheoDiem: false,
        data: {
            thongTinXe: [],
            thongTinDichVuXe: [],
            thongTinXepHangVaNhanXetXe: [],
            thongTinThemCuaXe: [],
        },
        model: {
            thongTinXeDangChon: null,
            tabDangChon: 0,
        }
    }
}
export default {
    namespaced: true,
    state: getDefaultState,
    getters: {
        //model
        getThongTinXeDangChon: state => state.model.thongTinXeDangChon,
        getTabDangChon: state => state.model.tabDangChon,
        //data
        getDataThongTinXe: state => state.data.thongTinXe,
        getDataThongTinDichVuXe: state => state.data.thongTinDichVuXe,
        getDataThongTinXepHangVaNhanXetXe: state => state.data.thongTinXepHangVaNhanXetXe,
        getDataThongTinThemCuaXe: state => state.data.thongTinThemCuaXe,
        getLayThongTinXepHangTheoDiem: state => state.layThongTinXepHangTheoDiem,
    },
    mutations: {
        set(state, { key, value }) {
            state[key] = value;
        },
        setModel(state, { key, value }) {
            state["model"][key] = value;
        },
        setData(state, { key, value }) {
            state["data"][key] = value;
        },
        setThongTinXeDangChon(state, value) {
            state.model.thongTinXeDangChon = value;
        },
        setTabDangChon(state, value) {
            state.model.tabDangChon = value;
        },
        resetContext(context) {
            Object.assign(context, getDefaultState());
        }
    },
    actions: {
        async layThongTinXe({ state, commit }) {
            try {
                if (!state.model.thongTinXeDangChon) {
                    throw new Error("Không có thongTinXeDangChon")
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinXe"), {
                    idChuyenDi: state.model.thongTinXeDangChon.id
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "thongTinXe", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinDichVuXe({ state, commit }) {
            try {
                if (!state.model.thongTinXeDangChon) {
                    throw new Error("Không có thongTinXeDangChon")
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinDichVuXe"), {
                    idXe: state.model.thongTinXeDangChon.xe_Id
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "thongTinDichVuXe", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinXepHangVaNhanXetXe({ state, commit }) {
            try {
                if (!state.model.thongTinXeDangChon) {
                    throw new Error("Không có thongTinXeDangChon")
                }
                commit("set", { key: "layThongTinXepHangTheoDiem", value: false })
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinDanhGia"), {
                    idXe: state.model.thongTinXeDangChon.xe_Id
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "thongTinXepHangVaNhanXetXe", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinThemCuaXe({ state, commit }) {
            try {
                if (!state.model.thongTinXeDangChon) {
                    throw new Error("Không có thongTinXeDangChon")
                }
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinThem"), {
                    guidChuyenDi: state.model.thongTinXeDangChon.id
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "thongTinThemCuaXe", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layThongTinDanhGiaTheoDiem({ state, commit }, diemDanhGia) {
            try {
                if (!state.model.thongTinXeDangChon) {
                    throw new Error("Không có thongTinXeDangChon")
                }
                commit("set", { key: "layThongTinXepHangTheoDiem", value: true })
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.ThongTinDanhGia"), {
                    idXe: state.model.thongTinXeDangChon.xe_Id,
                    diemDanhGia: typeof diemDanhGia == "number" ? diemDanhGia : 0
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    commit("setData", { key: "thongTinXepHangVaNhanXetXe", value: data })
                    return rs.Data.data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        chuyenDiKhac({ rootGetters, state, commit, dispatch }, { action }) {
            if (!state.model.thongTinXeDangChon) {
                throw new Error("Không có thongTinXeDangChon")
            }
            commit("banDo/setOld", { key: "loTrinh", value: [] }, { root: true })
            commit("timKiemChuyenDi/setOld", { key: "diemDung", value: "" }, { root: true })
            dispatch("banDo/xoaDiemDung", null, { root: true })
            dispatch("banDo/xoaDsLoTrinh", null, { root: true })
            let sttThongTinXe = state.model.thongTinXeDangChon.stt;
            let stt = action == "pre" ? sttThongTinXe - 1 : sttThongTinXe + 1
            let chuyenDiKhac = rootGetters["timKiemChuyenDi/getDataDsTatCaChuyenDi"].filter(e => e.stt == stt)
            if (chuyenDiKhac.length > 0) {
                commit("setModel", { key: "thongTinXeDangChon", value: chuyenDiKhac[0] })
            }
        }
    }
}
export default {
    namespaced: true,
    state: () => {
        manHinh: "doc"; //true 1080x1920; false 1920x1080
    },

    mutations: {
        SET_MAN_HINH(state, kieuManHinh){
            state.manHinh = kieuManHinh
        }
    },
    actions: {
        manHinhAction({ commit }, kieuManHinh) {
            commit("SET_MAN_HINH", kieuManHinh);
        },
    },
};

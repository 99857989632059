const getDefaultState = () => {
    return {
        reloadMaTranCho: 0,
        removeTatCaChoDaChon: 0,
        removeMotChoDaChon: null,
        resetData: null,
        choDaChon: null,
        layMaTranCho: null,
        data: {
            danhSachTangXe: []
        },
        model: {
            danhSachChoDaChon: [],
            tangXeDaChon: {
                soCot: 0,
                soHang: 0
            }
        }
    }
}
export default {
    namespaced: true,
    state: getDefaultState,
    getters: {
        removeTatCaChoDaChon: state => state.removeTatCaChoDaChon,
        removeMotChoDaChon: state => state.removeMotChoDaChon,
        resetData: state => state.resetData,
        choDaChon: state => state.choDaChon,
        getDanhSachTangXe: state => state.data.danhSachTangXe,
        reloadMaTranCho: state => state.reloadMaTranCho,
        tongTienTheoChoDaChon: state => {
            let dsChoDaChon = JSON.parse(
                localStorage.getItem("choDaChon")
            );
            let tongTien = 0;
            if (state.model.danhSachChoDaChon &&
                state.model.danhSachChoDaChon.length > 0) {
                state.model.danhSachChoDaChon.forEach(e => {
                    tongTien += e.giaTien;
                })
            } else if (dsChoDaChon) {
                if (dsChoDaChon.length > 0) {
                    dsChoDaChon.forEach((e) => {
                        tongTien += e.giaTien;
                    });
                }
            }
            return tongTien;
        },
        getDanhSachChoDaChonLocal: state => {// Được sử dụng tại các page khác
            let choDaChon = JSON.parse(
                localStorage.getItem("choDaChon")
            );
            return choDaChon
        },
        //model
        getDanhSachChoDaChon: state => state.model.danhSachChoDaChon,
        getTangXeDaChon: state => state.model.tangXeDaChon,
    },
    mutations: {
        set(state, data) {
            state[data.key] = data.value;
        },
        setData(state, data) {
            state["data"][data.key] = data.value;
        },
        removeTatCaChoDaChon(state) {
            state.removeTatCaChoDaChon++
        },
        removeMotChoDaChon(state, value) {
            state.removeMotChoDaChon = value
        },
        resetData(state) {
            state.resetData++
        },
        setChoDaChon(state, value) {
            state.choDaChon = value;
        },
        setDanhSachChoDaChon(state, value) {
            state.model.danhSachChoDaChon = value;
        },
        setReloadMaTranCho(state) {
            state.reloadMaTranCho++;
        },
        setTangXeDaChon(state, value) {
            state.model.tangXeDaChon = value;
        },
        setDanhSachTangXe(state, value) {
            state.data.danhSachTangXe = value;
        },
        removeDanhSachChoDaChonLocal() {// Được sử dụng tại các page khác
            localStorage.removeItem("choDaChon")
        },
        resetContext(context) {
            Object.assign(context, getDefaultState());
        }
    },
    actions: {
        async layMaTranCho({ state }, idChuyenDi) {
            try {
                if (!state.model.tangXeDaChon) {
                    throw new Error("Không có tangXeDaChon")
                }
                if (!idChuyenDi) {
                    throw new Error("Không có idChuyenDi")
                }
                this.$loading.show();
                let rs = await window.$Core.Api.Make(this.$i18n.global.t("url.MaTranChoNgoi"), {
                    IdChuyenDi: idChuyenDi,
                    IdTang: state.model.tangXeDaChon.idTang
                }).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    return data;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
        async layDanhSachTangXe({ commit }, idChuyenDi) {
            try {
                if (!idChuyenDi) {
                    throw new Error("Không có idChuyenDi")
                }
                // commit("set", { key: "layThongTinXepHangTheoDiem", value: true })
                this.$loading.show();
                let rs = await this.$Core.Api.Make(
                    this.$i18n.global.t("url.DanhSachTangXe"), {
                        IdChuyenDi: idChuyenDi,
                }
                ).Get();
                this.$loading.hide();
                if (rs.StatusCode === 200) {
                    let data = rs.Data.data;
                    let tangXe = data.map((e, i) => {
                        e.active = (i == 0 ? true : false)
                        return e;
                    })
                    commit("setData", { key: "danhSachTangXe", value: tangXe })
                    return tangXe;
                }
            } catch (error) {
                this.$loading.hide();
                throw new Error(error)
            }
        },
    }
}